<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(various_accountings)="row">
            {{
              row.item?.various_accountings.length
                ? row.item?.various_accountings[0]?.title
                : ""
            }}
          </template>
          <template #cell(various_accounting_code)="row">
            {{
              row.item.various_accountings.length
                ? row.item.various_accountings[0]?.code
                : ""
            }}
          </template>
          <template #cell(note_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenNoteModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Creato da',
                      key: 'owner_id',
                      formatter: (val) => retriveOwner(val),
                    },
                    {
                      label: 'Data creazione',
                      key: 'created_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 2)
                  .length
              }}
            </div>
          </template>
          <template #cell(document_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenDocModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(row.item.documents, 'Documenti', [
                  { label: 'Titoli', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  { label: 'Ultimo aggiornamento', key: 'last_update' },
                ])
              "
            >
              {{ row.item.documents.length }}
            </div>
          </template>

          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @show="onModalShow"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/accounting/various-accounting/details/General.vue";
import Appointments from "@/components/accounting/various-accounting/details/Appointments";
import Notes from "@/components/accounting/various-accounting/details/Notes";
import Documents from "@/components/accounting/book-entries/details/Documents";
import Todo from "@/components/accounting/various-accounting/details/Todo";
import { toLocaleCurrency } from "@/utils/strings";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  name: "BookEntriesVariousAccoutingTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Documents", text: "Doc" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    General,
    ButtonGroupTab,
    Todo,
    Notes,
    Appointments,
    Documents,
    BaseInputPagination,
  },
  methods: {
    toLocaleCurrency,
    onModalShow() {
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      this.setTab({ name: "General", text: "Generale" }, 0);
    },
    customOpenNoteModal(item, index, target) {
      this.tabIndex = 2; // TabIndex ID delle Note
      this.openModal(item, index, target);
    },
    customOpenDocModal(item, index, target) {
      this.tabIndex = 4; // TabIndex ID dei Documenti
      this.openModal(item, index, target);
    },
    customized(item) {
      let c = {};
      item.entry_details.forEach((entry_detail) => {
        if (entry_detail.gross !== 0) {
          c[entry_detail.treasury.title] = this.toLocaleCurrency(
            entry_detail.gross
          );
        }
      });
      return c;
    },
    retriveOwner(value) {
      if (!value) return "-";
      return (
        this.getUsers().find((user) => user.value === value).username ?? "-"
      );
    },
    ...mapGetters("auth", {
      getUsers: "users",
    }),
  },
};
</script>
